import React from 'react';
import { NavLink } from 'react-router-dom';
import './_NavLinks.scss';

const SuperAdminNavLinks = ({ closeDrawer }) => {
  const navItems = [
    { path: '/patients', label: 'PACIENTES' },
    { path: '/professionals', label: 'PROFESIONALES' },
    { path: '/admins', label: 'ADMINS' },
    { path: '/standalone-admins/', label: 'STANDALONE ADMINS' },
    { path: '/super-admins', label: 'SUPERADMINS' },
    { path: '/health-facilities', label: 'CENTROS' },
    { path: '/medicines', label: 'MEDICAMENTOS' },
    { path: '/logger', label: 'LOGGER' },
    { path: '/advices', label: 'CONSEJOS' },
  ];

  return (
    <>
      {navItems.map((item, index) => (
        <li key={index}>
          <NavLink to={item.path} onClick={closeDrawer}>
            {item.label}
          </NavLink>
        </li>
      ))}
    </>
  );
};

export default SuperAdminNavLinks;
